import React from 'react'
import styled, { keyframes } from 'styled-components'

const colors = {
  orange: '#F09765',
  purple: '#7A5BD2',
  blue: '#148BE9',
}

const StyledPath = styled.path`
  fill: #28283d;
`

const orangePurpleBlue = keyframes`
  0% {
    fill: ${colors.orange};
  }

  33% {
    fill: ${colors.purple};
  }

  66% {
    fill: ${colors.blue};
  }

  100% {
    fill: ${colors.orange};
  }
`

const purpleBlueOrange = keyframes`
  0% {
    fill: ${colors.purple};
  }

  33% {
    fill: ${colors.blue};
  }

  66% {
    fill: ${colors.orange};
  }

  100% {
    fill: ${colors.purple};
  }
`

const blueOrangePurple = keyframes`
  0% {
    fill: ${colors.blue};
  }

  33% {
    fill: ${colors.orange};
  }

  66% {
    fill: ${colors.purple};
  }

  100% {
    fill: ${colors.blue};
  }
`

const AnimateGroup = styled.g`
  #dot {
    animation: ${orangePurpleBlue} 2s ease-in-out infinite;
  }

  #letter-o {
    animation: ${purpleBlueOrange} 2s ease-in-out infinite;
  }

  #letter-t {
    animation: ${blueOrangePurple} 2s ease-in-out infinite;
  }
`

interface Props {
  className?: string
  onClick?: () => void
}

const Logo = ({ className, onClick }: Props) => (
  <svg
    width="564"
    height="133"
    viewBox="0 0 564 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <AnimateGroup>
      <StyledPath
        id="letter-o"
        d="M484.225 65.5902C488.742 65.5902 493.157 66.9296 496.913 69.4391C500.669 71.9485 503.596 75.5153 505.325 79.6884C507.053 83.8615 507.505 88.4535 506.624 92.8836C505.743 97.3138 503.568 101.383 500.374 104.577C497.18 107.771 493.111 109.946 488.68 110.827C484.25 111.709 479.658 111.256 475.485 109.528C471.312 107.799 467.745 104.872 465.236 101.116C462.726 97.3606 461.387 92.9451 461.387 88.4282C461.394 82.3732 463.802 76.5681 468.083 72.2866C472.365 68.005 478.17 65.5968 484.225 65.5902ZM484.225 44.3164C475.501 44.3164 466.972 46.9035 459.718 51.7506C452.464 56.5976 446.81 63.4869 443.471 71.5473C440.132 79.6077 439.259 88.4771 440.961 97.0339C442.663 105.591 446.864 113.451 453.033 119.62C459.202 125.789 467.062 129.99 475.619 131.692C484.176 133.394 493.046 132.521 501.106 129.182C509.166 125.843 516.056 120.189 520.903 112.935C525.75 105.681 528.337 97.1526 528.337 88.4282C528.338 82.6351 527.197 76.8986 524.981 71.5463C522.764 66.1941 519.515 61.3309 515.419 57.2346C511.322 53.1383 506.459 49.8891 501.107 47.6725C495.755 45.456 490.018 44.3156 484.225 44.3164Z"
      />
    </AnimateGroup>

    <AnimateGroup>
      <StyledPath
        id="dot"
        d="M551.034 132.696C557.945 132.696 563.547 127.093 563.547 120.182C563.547 113.271 557.945 107.668 551.034 107.668C544.122 107.668 538.52 113.271 538.52 120.182C538.52 127.093 544.122 132.696 551.034 132.696Z"
      />
    </AnimateGroup>

    <g id="letter-i">
      <StyledPath d="M353.698 35.1744C360.609 35.1744 366.212 29.5717 366.212 22.6605C366.212 15.7492 360.609 10.1465 353.698 10.1465C346.786 10.1465 341.184 15.7492 341.184 22.6605C341.184 29.5717 346.786 35.1744 353.698 35.1744Z" />
      <StyledPath d="M353.699 43.9961C350.878 43.9961 348.173 45.1168 346.178 47.1116C344.183 49.1064 343.062 51.8119 343.062 54.633V121.702C343.062 124.523 344.183 127.228 346.178 129.223C348.173 131.218 350.878 132.338 353.699 132.338C356.52 132.338 359.226 131.218 361.221 129.223C363.216 127.228 364.336 124.523 364.336 121.702V54.633C364.336 51.8119 363.216 49.1064 361.221 47.1116C359.226 45.1168 356.52 43.9961 353.699 43.9961Z" />
    </g>

    <StyledPath
      id="letter-m"
      d="M290.703 44.2149C281.356 44.194 272.362 47.7801 265.594 54.2261C258.825 47.7801 249.831 44.194 240.484 44.2149C220.775 44.2149 204.738 59.7135 204.738 78.766V121.701C204.738 124.523 205.859 127.228 207.854 129.223C209.849 131.218 212.554 132.338 215.375 132.338C218.196 132.338 220.902 131.218 222.897 129.223C224.891 127.228 226.012 124.523 226.012 121.701V78.766C226.012 71.4453 232.507 65.4887 240.484 65.4887C248.462 65.4887 254.957 71.4453 254.957 78.766V121.701C254.957 124.523 256.078 127.228 258.072 129.223C260.067 131.218 262.773 132.338 265.594 132.338C268.415 132.338 271.12 131.218 273.115 129.223C275.11 127.228 276.231 124.523 276.231 121.701V78.766C276.231 71.4453 282.725 65.4887 290.703 65.4887C298.681 65.4887 305.175 71.4453 305.175 78.766V121.701C305.175 124.523 306.296 127.228 308.291 129.223C310.286 131.218 312.991 132.338 315.812 132.338C318.633 132.338 321.339 131.218 323.334 129.223C325.329 127.228 326.449 124.523 326.449 121.701V78.766C326.449 59.7135 310.444 44.2149 290.703 44.2149Z"
    />
    <StyledPath
      id="letter-u"
      d="M149.107 132.338C138.74 132.327 128.8 128.201 121.472 120.868C114.143 113.535 110.024 103.593 110.02 93.2258V53.8068C110.02 50.9857 111.14 48.2802 113.135 46.2854C115.13 44.2906 117.835 43.1699 120.656 43.1699C123.477 43.1699 126.183 44.2906 128.178 46.2854C130.173 48.2802 131.293 50.9857 131.293 53.8068V93.2258C131.249 95.5928 131.677 97.9449 132.552 100.145C133.427 102.344 134.732 104.348 136.39 106.037C138.048 107.727 140.027 109.069 142.21 109.985C144.393 110.901 146.736 111.373 149.104 111.373C151.471 111.373 153.815 110.901 155.998 109.985C158.181 109.069 160.159 107.727 161.817 106.037C163.476 104.348 164.78 102.344 165.656 100.145C166.531 97.9449 166.959 95.5928 166.914 93.2258V53.8068C166.914 50.9857 168.035 48.2802 170.03 46.2854C172.025 44.2906 174.73 43.1699 177.551 43.1699C180.372 43.1699 183.078 44.2906 185.073 46.2854C187.067 48.2802 188.188 50.9857 188.188 53.8068V93.2258C188.185 103.593 184.067 113.534 176.74 120.868C169.412 128.201 159.474 132.327 149.107 132.338Z"
    />
    <AnimateGroup>
      <StyledPath
        id="letter-t"
        d="M425.661 111.064H421.475C416.751 111.054 412.224 109.17 408.887 105.825C405.551 102.481 403.678 97.9492 403.68 93.2252V65.2689H422.495C425.316 65.2689 428.021 64.1483 430.016 62.1535C432.011 60.1587 433.132 57.4531 433.132 54.6321C433.132 51.811 432.011 49.1055 430.016 47.1107C428.021 45.1159 425.316 43.9952 422.495 43.9952H403.68V12.7541C403.68 9.93299 402.559 7.22746 400.565 5.23266C398.57 3.23785 395.864 2.11719 393.043 2.11719C390.222 2.11719 387.517 3.23785 385.522 5.23266C383.527 7.22746 382.406 9.93299 382.406 12.7541V93.2252C382.418 103.587 386.539 113.521 393.865 120.849C401.192 128.177 411.125 132.299 421.487 132.313H425.673C428.494 132.313 431.2 131.192 433.195 129.197C435.19 127.202 436.31 124.497 436.31 121.676C436.31 118.855 435.19 116.149 433.195 114.154C431.2 112.159 428.494 111.039 425.673 111.039L425.661 111.064Z"
        fill="#F09765"
      />
    </AnimateGroup>

    <StyledPath
      id="letter-y"
      d="M103.168 1.66209C100.788 0.149601 97.9048 -0.35598 95.1523 0.256479C92.3997 0.868939 90.003 2.54931 88.489 4.92824L54.2758 58.7383L20.0626 4.92824C18.5483 2.54775 16.1504 0.866291 13.3964 0.25377C10.6424 -0.358751 7.75791 0.147837 5.37742 1.66209C2.99693 3.17634 1.31547 5.57422 0.702952 8.32822C0.399662 9.69186 0.367929 11.1019 0.609569 12.4778C0.851209 13.8537 1.36149 15.1685 2.11127 16.3472L43.5575 81.5075V121.702C43.5575 124.523 44.6782 127.229 46.673 129.224C48.6678 131.219 51.3733 132.339 54.1944 132.339C57.0155 132.339 59.721 131.219 61.7158 129.224C63.7106 127.229 64.8313 124.523 64.8313 121.702V81.7578L106.434 16.3472C107.184 15.1686 107.694 13.8537 107.936 12.4778C108.178 11.1019 108.146 9.69182 107.843 8.32815C107.539 6.96448 106.97 5.6739 106.168 4.53013C105.366 3.38636 104.347 2.4118 103.168 1.66209Z"
    />
  </svg>
)

export default Logo
