import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import cookie from 'js-cookie'

import { GATE_COOKIE } from '@/config/config'

import Logo from './Logo'
import { isServer } from '@/clients/apollo'

const Layout = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-right: 40px;
  padding-left: 40px;
`

const Label = styled.span`
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 38px;
  text-align: center;

  color: #28283d;
`

let timer: any

const Landing = () => {
  const [counter, setCounter] = useState(0)
  const router = useRouter()

  const startTimer = () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      setCounter(0)
    }, 250)
  }

  const handleKeyDown = (e: Event) => {
    // @ts-expect-error this is fine
    if (e.keyCode === 32) {
      setCounter((prevState) => prevState + 1)
      startTimer()
    }
  }

  const handleClick = () => {
    setCounter((prevState) => prevState + 1)
    startTimer()
  }

  useEffect(() => {
    if (document) {
      document.addEventListener('keydown', handleKeyDown)

      return () => document.removeEventListener('keydown', handleKeyDown)
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  }, [])

  useEffect(() => {
    if (counter === 3 && !isServer) {
      // eslint-disable-next-line no-alert
      const value = window.prompt('Password:')
      if (value === 'krybits') {
        cookie.set(GATE_COOKIE, 'true', { expires: 2147483647 })
        router.push('/restaurants')
      }
    }
  }, [counter])

  return (
    <Layout>
      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito&display=swap"
          rel="stylesheet"
        />
      </Head>
      <Logo
        onClick={handleClick}
        css={{ marginBottom: 30, maxWidth: '100%' }}
      />
      <Label>Kulinarna rewolucja nadchodzi!</Label>
    </Layout>
  )
}

export default Landing
